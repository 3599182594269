import * as React from "react";
import { AskAboutQuery_RichAnswer } from "../../../../../graphql-types";
import SocialShare from "../../../ui-library/social-share/social-share";
import Feedback from "../../../feedback/feedback";
import { Topic } from "../../../../types/topics";
import RichAnswers from "../../../common-page-sections/rich-answers/rich-answers";

interface BlockchainAnswersProps {
  alias: string;
  intentName: string;
  displayName: string;
  messages?: string[] | null;
  richAnswers: AskAboutQuery_RichAnswer[];
}

const BlockchainAnswers: React.FC<BlockchainAnswersProps> = ({
  alias,
  intentName,
  displayName,
  messages,
  richAnswers,
}) => {
  return (
    <>
      {/* {intentName === SPECIAL_INTENTS.Topics.name && (
        <Container>
          <BlockchainCategories />
        </Container>
      )} */}

      <RichAnswers displayName={displayName} richAnswers={richAnswers} />

      <Feedback intentName={intentName} topic={Topic.Blockchain} />

      <SocialShare
        title={displayName}
        description={messages && messages.length > 0 ? messages[0] : ""}
        url={`/blockchain/ask/${alias}/`}
      />
    </>
  );
};

export default BlockchainAnswers;
