import * as React from "react";
import { PageProps } from "gatsby";
import Layout from "../../components/layout";
import Container from "../../components/ui-library/container/container";
import { AskAboutQuery_FbIntent } from "../../../graphql-types";
import PageContainer from "../../components/ui-library/page-container/page-container";
import PageHeading from "../../components/ui-library/page-heading/page-heading";
import SEO from "../../components/seo/seo";
import FAQSchema from "../../components/seo/faq-schema";
import SuggestedIntent from "../../components/common-page-sections/suggested-intent/suggested-intent";
import CollectQAndA from "../../components/common-page-sections/collect-q-and-a/collect-q-and-a";
import { LogoBunny } from "../../components/site/header/header";
import { Topic } from "../../types/topics";
import { mapTopicUrlSegment } from "../../utils/map-topics";
import BlockchainAnswers from "../../components/blockchain/blockchain-intent/blockchain-answers/blockchain-answers";

interface IntentData {
  intent: AskAboutQuery_FbIntent;
}

type BlockchainAskIntentsProps = PageProps<null, IntentData>;

const BlockchainAskIntents: React.FC<BlockchainAskIntentsProps> = ({
  pageContext: { intent },
}) => {
  const url = `/${mapTopicUrlSegment(Topic.Blockchain)}/`;

  return (
    <Layout
      topic={Topic.Blockchain}
      description="digital economy"
      headerUrl={url}
      logoBunny={LogoBunny.Main}
    >
      <SEO
        title={intent.displayName}
        description={(!!intent.messages && intent.messages[0]) || undefined}
        imageUrl={`/media/images/social-share/blockchain/${intent.alias}.jpg`}
      />

      <FAQSchema intent={intent} />

      <PageContainer>
        <PageHeading backUrl={url}>{intent.displayName}</PageHeading>

        <Container fullWidthOnMobile={true}>
          <BlockchainAnswers {...intent} />
        </Container>

        <Container>
          {!!intent.suggestedIntent && (
            <SuggestedIntent
              suggestedIntent={intent.suggestedIntent}
              topic={Topic.Blockchain}
            />
          )}

          <CollectQAndA />
        </Container>
      </PageContainer>
    </Layout>
  );
};

export default BlockchainAskIntents;
