import * as React from "react";
import { AskAboutQuery_SuggestedIntent } from "../../../../graphql-types";
import Section from "../../ui-library/section/section";
import QuestionLink from "../../ui-library/question-link/question-link";
import { EventCategory } from "../../../utils/analytics";
import { Topic } from "../../../types/topics";
import { mapTopicUrlSegment } from "../../../utils/map-topics";

interface SuggestedIntentProps {
  topic: Topic;
  suggestedIntent: AskAboutQuery_SuggestedIntent;
}

const SuggestedIntent: React.FC<SuggestedIntentProps> = ({
  topic,
  suggestedIntent,
}) => {
  return (
    <Section heading="You might also want to ask..." align="right">
      <QuestionLink
        to={`/${mapTopicUrlSegment(topic)}/ask/${suggestedIntent.alias}/`}
        eventCategory={EventCategory.Section_SuggestedAsk}
      >
        {suggestedIntent.displayName}
      </QuestionLink>
    </Section>
  );
};

export default SuggestedIntent;
